const defaultTheme = {
    colors: {
        aliceBlue: '#E6F6FF',
        black: '#000000',
        darkBlue: '#013A57',
        error: ' #C52323',
        free: '#46A25A70',
        green: '#46A25A',
        motherHover: '#026597',
        motherText: '#232F35',
        neutral100: '#C4CBD4',
        neutral200: '#E3E5E8',
        neutral300: '#687280',
        neutral400: '#8E9BAB',
        neutral50: '#F8FAFC',
        neutral500: '#3c465b',
        neutral900: '#20263b',
        paleBlue: '#CDEEFE',
        paleSky: '#687280',
        primaryBlue100: '#E6EDF5',
        primaryBlue200: '#DBE5F0',
        primaryBlue300: '#B8C9E0',
        primaryBlue400: '#7A98BF',
        primaryBlue50: '#F7FAFD',
        primaryBlue500: '#466BA2',
        primaryBlue60: '#F7FAFE',
        primaryBlue600: '#34538A',
        primaryBlue700: '#263E71',
        primaryBlue800: '#1A2C59',
        primaryBlue900: '#121F48',
        red: '#DD4326',
        reserve: '#E0B85270',
        sold: '#DD432670',
        textPrimary: '#231F20',
        textPrivacy: '#5D7EAD',
        textSection: '#576375',
        white: '#FFFFFF',
        yellow: '#E0B852',
    },
    fontSizes: {
        f12: '12px',
        f14: '14px',
        f16: '16px',
        f18: '18px',
        f20: '20px',
        f24: '24px',
        f28: '28px',
        f30: '30px',
        f34: '34px',
        f36: '36px',
        f40: '40px',
        f48: '48px',
        f56: '56px',
        f64: '64px',
    },
    fontWeight: {
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    letterSpacing: {
        xxs: '-0.005em',
        xs: '0.005em',
        s: '0.01em',
        m: '0.015em',
        l: '0.02em',
        xl: '0.05em',
    },
    containerWidth: {
        motherPage: '1225px',
        defalut: '1170px',
        lg: '960px',
        md: '720px',
        sm: '540px',
        xs: '100%',
    },
};

export default defaultTheme;
