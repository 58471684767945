import styled, { css } from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const CloseButton = styled.button`
    align-self: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
`;

export const StyledToastContainer = styled(ToastContainer)(
    ({ theme: { fontWeight, fontSizes, colors, letterSpacing } }) => css`
        max-width: 386px;
        min-width: 300px;
        transition: all 0.5s;
        width: max-content;
        @media (max-width: 480px) {
            margin-bottom: 16px;
            max-width: 320px;
            min-width: 300px;
        }

        .Toastify {
            &__toast {
                font-size: ${fontSizes.f16}px;
                font-weight: ${fontWeight.medium};
                line-height: 28px;
                min-height: 50px;
                padding: 12px 64px 12px 24px;
                text-align: left;
                color: ${colors.white};
                letter-spacing: ${letterSpacing.s};

                @media (max-width: 480px) {
                    font-size: ${fontSizes.f16}px;
                    margin-bottom: 16px;
                    padding: 16px 72px 16px 24px;
                }

                &--success {
                    background: ${colors.green};
                }

                &--error {
                    background: ${colors.red};
                }
            }

            &__toast-body {
                margin: 0;
                padding: 0;
            }
            &__toast-icon {
                display: none;
            }
        }
    `,
);
