export const breakpoints = {
    xs: 576,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1441,
};

export const mediaQueries = {
    xs: `@media (max-width: ${breakpoints.xs}px)`,
    sm: `@media (max-width: ${breakpoints.sm}px)`,
    md: `@media (max-width: ${breakpoints.md}px)`,
    lg: `@media (max-width: ${breakpoints.lg}px)`,
    xl: `@media (min-width: ${breakpoints.xl}px)`,
};
