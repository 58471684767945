import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

export default createGlobalStyle<{
    mobileMenuIsOpen?: boolean;
    isStickyMenu?: boolean;
}>`
  ${normalize}

  ${({ mobileMenuIsOpen, isStickyMenu }) => css`
      html {
          box-sizing: border-box;
          overflow: ${mobileMenuIsOpen ? 'hidden' : 'overlay'};
      }
      body {
          -webkit-font-smoothing: antialiased;
          font-family: 'Montserrat', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.25;
          margin: 0;
          ${!isStickyMenu && 'overflow-x: hidden;'}
          max-width: 100vw;
      }
  `}

  
 
  
  * {
    box-sizing: border-box;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  
  p {
    margin: 0;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  #root {
    height: 100%;
  }
  
  svg {
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }
  
  .svg-icon{
    width: 1em;
    height: 1em;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 3px;
  }
`;
