import { Link } from 'gatsby';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const SectionWrapper = styled.div(
    ({ theme: { containerWidth } }) =>
        css`
            display: flex;
            margin: 0 auto;
            max-width: ${containerWidth.defalut};
            width: ${containerWidth.xs};

            ${mediaQueries.xs} {
                width: unset;
                height: 100%;
            }
        `,
);

export const H1 = styled.h1(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.textPrimary};
            font-size: ${fontSizes.f56};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.xxs};
            line-height: 80px;

            ${mediaQueries.xs} {
                font-size: ${fontSizes.f34};
                line-height: 46px;
            }
        `,
);

export const H2 = styled.h2(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.textPrimary};
            font-size: ${fontSizes.f48};
            font-weight: ${fontWeight.semiBold};
            line-height: 64px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f30};
                line-height: 36px;
            }
        `,
);

export const H3 = styled.h3(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.textPrimary};
            font-size: ${fontSizes.f34};
            font-weight: ${fontWeight.bold};
            line-height: 54px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f24};
            }
        `,
);

export const H4 = styled.h4(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.textPrimary};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.s};
            line-height: 24px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f14};
            }
        `,
);

export const H5 = styled.h5(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.textPrimary};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.bold};
            letter-spacing: ${letterSpacing.m};
            line-height: 18px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f14};
            }
        `,
);

export const HeroText = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.neutral500};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.medium};
            letter-spacing: ${letterSpacing.s};
            line-height: 32px;
            max-width: 470px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f14};
                line-height: 22px;
            }
        `,
);

export const SectionText = styled.p(
    ({ theme: { colors, fontSizes, letterSpacing, fontWeight } }) =>
        css`
            color: ${colors.textSection};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.medium};
            letter-spacing: ${letterSpacing.s};
            line-height: 28px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f14};
            }
        `,
);

export const Text = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.neutral900};
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.medium};
            letter-spacing: ${letterSpacing.m};
            line-height: 18px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f12};
            }
        `,
);

export const MotherWebsiteTitle = styled.h2(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.darkBlue};
            font-size: ${fontSizes.f48};
            font-weight: ${fontWeight.semiBold};
            line-height: 64px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f30};
                letter-spacing: ${letterSpacing.xxs};
                line-height: 36px;
            }
        `,
);

export const MotherWebsiteText = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.motherText};
            font-size: ${fontSizes.f18};
            font-weight: ${fontWeight.regular};
            letter-spacing: ${letterSpacing.m};
            line-height: 28px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f16};
                line-height: 24px;
            }
        `,
);

export const sharedButtonStyles = css(
    ({ theme: { fontSizes, fontWeight, letterSpacing } }) =>
        css`
            align-items: center;
            box-sizing: border-box;
            display: flex;
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.bold};
            justify-content: center;
            letter-spacing: ${letterSpacing.s};
            line-height: 24px;
            padding: 16px 32px;
            text-align: center;
            text-decoration: none;
            width: fit-content;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f14};
                letter-spacing: ${letterSpacing.m};
                line-height: 18px;
                padding: 16px 28px;
            }
        `,
);

export const PrimaryButton = styled(Link)(
    ({ theme: { colors } }) =>
        css`
            ${sharedButtonStyles}
            background: ${colors.primaryBlue500};
            border: 2px solid transparent;
            color: ${colors.white};
            cursor: pointer;

            &:hover {
                background: ${colors.primaryBlue700};
            }

            &[disabled] {
                background: ${colors.neutral100};
                cursor: not-allowed;
            }
        `,
);

export const SecondaryButton = styled(Link)(
    ({ theme: { colors } }) =>
        css`
            ${sharedButtonStyles}
            background: ${colors.white};
            border: 2px solid ${colors.primaryBlue500};
            color: ${colors.primaryBlue500};

            &:hover {
                color: ${colors.white};
                background: ${colors.primaryBlue500};
            }
        `,
);

export const TransparentButton = styled(Link)(
    ({ theme: { colors } }) =>
        css`
            ${sharedButtonStyles}
            background: rgba(32, 38, 59, 0.4);
            border: 2px solid ${colors.white};
            color: ${colors.white};

            &:hover {
                background: rgba(32, 38, 59, 0.8);
            }
        `,
);

export const InfoBigNumber = styled.p(
    ({ theme: { colors, fontSizes, fontWeight } }) => css`
        color: ${colors.primaryBlue400};
        font-size: ${fontSizes.f34};
        font-weight: ${fontWeight.bold};
        line-height: 54px;
    `,
);

export const LegalContainer = styled(SectionWrapper)`
    margin: 60px auto;
    z-index: 3;

    ${mediaQueries.md} {
        padding: 0 20px;
    }
`;

export const UniversalHeroWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background-color: ${colors.primaryBlue50};
            display: flex;
            flex-direction: column;
            max-width: 100%;
            padding: 80px 136px;
            z-index: 3;

            ${mediaQueries.md} {
                padding: 72px 80px;
            }

            ${mediaQueries.sm} {
                padding: 48px 28px;
            }

            ${mediaQueries.xs} {
                align-items: center;
                padding: 40px 16px;
                text-align: center;
            }
        `,
);

export const HeroTitle = styled(H1)`
    margin-top: 40px;
    margin-bottom: 24px;

    ${mediaQueries.xs} {
        margin-top: 0;
        margin-bottom: 16px;
    }
`;
