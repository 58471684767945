import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { ImageType } from 'types/CommonTypes';
import Icon from 'images/parasol-favicon.png';

interface IHeadProps {
    article?: boolean;
    description?: string;
    investmentSettings?: { investmentFavicon: ImageType };
    pathname: string;
    thumbnail?: string;
    title?: string;
}

export default ({
    article,
    description,
    investmentSettings,
    pathname,
    thumbnail,
    title,
}: IHeadProps) => (
    <StaticQuery
        query={QueryHead}
        render={({
            site: {
                siteMetadata: {
                    site,
                    defaultTitle,
                    titleTemplate,
                    defaultDescription,
                    language,
                    siteUrl,
                    color,
                    twitter,
                },
            },
        }) => {
            const seo = {
                title: title || defaultTitle,
                description: description || defaultDescription,
                image: thumbnail || `${siteUrl}/assets/thumbnail.png`,
                url: `${siteUrl}${pathname}`,
                twitter,
            };

            useEffect(() => {
                const script = document.createElement('script');
                script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
                    {'gtm.start': new Date().getTime(),event:'gtm.js'}
                  );var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-5Q62TK9');`;

                document.head.appendChild(script);

                return () => {
                    document.head.removeChild(script);
                };
            }, []);

            return (
                <Helmet title={seo.title} titleTemplate={titleTemplate}>
                    <html lang={language} />

                    <meta name="description" content={seo.description} />
                    <meta name="image" content={seo.image} />
                    <meta name="theme-color" content={color} />
                    <meta name="application-name" content={site} />
                    <link rel="canonical" href={seo.url} />

                    <meta property="og:url" content={seo.url} />
                    <meta property="og:title" content={seo.title} />
                    <meta property="og:description" content={seo.description} />
                    <meta property="og:image" content={seo.image} />
                    {article && <meta property="og:type" content="article" />}

                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-title" content={site} />
                    <meta
                        name="apple-mobile-web-app-status-bar-style"
                        content="black-translucent"
                    />

                    <meta name="twitter:creator" content={seo.twitter} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={seo.title} />
                    <meta
                        name="twitter:description"
                        content={seo.description}
                    />

                    <link
                        rel="icon"
                        href={
                            pathname !== '/' &&
                            investmentSettings?.investmentFavicon?.sourceUrl
                                ? investmentSettings?.investmentFavicon
                                      ?.sourceUrl
                                : Icon
                        }
                    />

                    <meta name="twitter:image" content={seo.image} />
                    <meta name="twitter:url" content={seo.url} />
                    <link
                        rel="preconnect"
                        href="https://fonts.googleapis.com"
                    />
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
                        rel="stylesheet"
                    ></link>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap"
                        rel="stylesheet"
                    ></link>
                </Helmet>
            );
        }}
    />
);

const QueryHead = graphql`
    query QueryHead {
        site {
            siteMetadata {
                site
                siteUrl
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                language
                color
                twitter
            }
        }
    }
`;
